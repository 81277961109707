import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'To be a futuristic in steel manufacturing organization and exceeding expectation of all the stakeholders.',
                    text02: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
                },
                {
                    id: 2,
                    text01: 'Super Steel has taken initiatives to enhance, improve and diversify its product set and has introduced various quality products from time to time. Our experienced and highly professional team is always at hand to assist and design solutions for specific customer requirement.',
                    text02: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
                },
                {
                    id: 3,
                    text01: 'To achieve this by appropriate state of the art technologies, high performing people, examinant productivity and sustainable development.',
                    text02: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SPECIAL SERVICES</div>
                                    <h2 className="heading font-size-28 line-height-39">Metal / Steel Transport&amp;  Logistics </h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">We work to develop and understand project expectations and then manage those needs with a customized, qualified team.</p>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        <div className="span_1_of_6 half-background style-2">
                            <img src="assets/img/supply/supply.png" alt="suply"/>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">Metal / Steel Transport And Logistics</h5>
                        <p>In addition to metal products, the steel manufacturing industry also produces a great deal of waste such as solvents, alkaline waste, strong acid waste, heavy metal wastewater and other dangerous byproducts. Whether you need to transport steel or metal waste, primary freight, parts and machinery, vehicle parts and accessories, scrap or fabricated products domestically or overseas, we have a solution. Seva Global Logistics can provide the transportation and logistics solutions to keep your materials or products moving, efficiently and cost-effectively.
                        </p>
                    </div>

                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title">TRANSPORT And LOGISTICS SERVICES IN THE STEEL INDUSTRY</h5>
                        <p>Firstly, it is necessary to prepare and manage the arrival of your merchandise, organize its control and storage or storage. Then the time will come for its shipment… it will then be necessary to organize this departure with vigilance, perhaps manage the stock, create a label or check it, check the packaging and its stability, check its administrative documents… a non-exhaustive list of several operations that our teams carry out every day to allow an optimized flow of goods without forgetting the essential security controls.</p>
                        <p>The steel industry is highly reliant on shipping and transportation. Timely shipments of raw materials such as coal and iron ore are required to continue production for steel manufacturers. Depending on the metal parts or equipment being manufactured, the timely and economical transport of bulky oversized metal sheets, parts or final products is also required. These types of steel and metal cargo are most often moved by rail, truck and ship or barge. Transporting steel and metal products by barge provides a cost-effective and competitive solution for shipments.</p>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    <div className="flat-content-wrap style-2 clearfix">
                        <h5 className="title no-letter-spacing">CONTACT US FOR DESIGN-BUILD SERVICES TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">Our focus</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Dedicated</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Committed</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                {/* <p>{data.text02}</p> */}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;