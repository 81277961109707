import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ServiceSlide extends Component {
    render() {
        return (
            <div className="row-services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={81} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">

                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p1.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">DEFROMED STEEL</Link></h5>
                                                    <p className="letter-spacing-01">Deformed bars have more tensile stresses than that of mild steel plain bars. These bars can be used without end hooks.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p2.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">RECTANGULAR MS PIPE</Link></h5>
                                                    <p className="letter-spacing-01">MS ERW Square & Rectangular Pipes are also called, Rectangular Hollow Section (RHS) or Square Hollow Sections (SHS). These are used for general engineering and structural purposes.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p3.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">SCAFFODING PIPE</Link></h5>
                                                    <p className="letter-spacing-01">Scaffolding pipe manufactured mostly with galvanized steel for high performance.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p5.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">ANGLE IRON</Link></h5>
                                                    <p className="letter-spacing-01">Steel Angle iron is one of the most popular hot rolled, low carbon steel shapes used in manufacturing, fabrication, and repair projects. From trailers to truck beds.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="themesflat-spacer clearfix" data-desktop={15} data-mobile={15} data-smobile={15} />
                            <div className="themesflat-carousel-box data-effect clearfix" data-gap={30} data-column={3}
                                data-column2={2} data-column3={1} data-auto="true">
                                <div className="owl-carousel owl-theme">

                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p4.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">FLAT IRON</Link></h5>
                                                    <p className="letter-spacing-01">Flat Bars are a long and rectangular-shaped metal bars are used in a wide range of structural and architectural applications.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p5.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">ANGLE IRON</Link></h5>
                                                    <p className="letter-spacing-01">Steel Angle iron is one of the most popular hot rolled, low carbon steel shapes used in manufacturing, fabrication, and repair projects. From trailers to truck beds.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p6.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">SCAFFODING CLUMP</Link></h5>
                                                    <p className="letter-spacing-01">scaffold clamp is a versatile type of scaffold consisting of steel tubes and clamps.  </p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="themesflat-image-box style-2 clearfix">
                                        <div className="image-box-item">
                                            <div className="inner">
                                                <div className="thumb data-effect-item">
                                                    <img src="assets/img/products/p2.png" alt="altimage" />
                                                    <div className="overlay-effect bg-color-accent" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h5 className="heading"><Link to="#">RECTANGULAR MS PIPE</Link></h5>
                                                    <p className="letter-spacing-01">MS ERW Square & Rectangular Pipes are also called, Rectangular Hollow Section (RHS) or Square Hollow Sections (SHS). These are used for general engineering and structural purposes.</p>
                                                    {/* <div className="elm-readmore">
                                                        <Link to="page-services-detail.html">DETAILS</Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceSlide;