import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Completing projects on time & Following budget guidelines'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Elevated quality of workmanship'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'Meeting diverse supplier requirements'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Implementing appropriate safety precautions and procedures'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row-about">
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-6 half-background style-1"/>
                        <div className="col-md-6 bg-light-grey">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">About Super Steel</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />                                               
                                    <p className="sub-heading margin-top-30">Over the years, Super Steel has taken initiatives to enhance, improve and diversify it's product set and has introduced various quality products from time to time. Our experienced and highly professional team is always at hand to assist and design solutions for specific customer requirement. Special customization is also accommodated where required.
SUPER STEEL is the largest and fastest re-rolling mill in Pakistan in terms of production capacity and rolling speed. Since its foundation in 1990, the company has excelled in the manufacturing of steel products meeting International Standards. We take pride not only in our technical superiority over other re-rolling mills, but also in our quality assurance procedures and processes.</p>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                <div className="content-list">
                                    <div className="themesflat-list has-icon style-1 icon-left clearfix">
                                        <div className="inner">
                                            {
                                                this.state.inner.map(data => (
                                                    <span className="item" key={data.id}>
                                                        <span className="icon"><i className={data.icon} /></span>
                                                        <span className="text">{data.text}</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                <div className="elm-button">
                                    <Link to="/contact-02" onClick={() => {window.location.href="/contact-02"}} className="themesflat-button bg-white">GET IN TOUCH</Link>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;