import React, { Component } from 'react';
import axiosWithoutAuth from '../../../config/service'



class FormContact02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email:'',
            contact:'',
            message:'',

            Modal: false,
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        this.setState({[event.target.name] : event.target.value});
      }

      closeModal = () => this.setState({ Modal: false})
    
      handleSubmit(event) {
        // alert('A message was submitted: ' + this.state.message);
        event.preventDefault();

        // axiosWithoutAuth().post(`/api/v1/contactus`, {
        //     name : this.state.name,
        //     email : this.state.email,
        //     contact : this.state.contact,
        //     message : this.state.message
        //   })
        //   .then(response =>{
        // //    console.log("response", response.status)
        //     if (response.status === 200) {
        //         this.setState({ Modal: true })
        //         setTimeout(() => {
        //             this.setState({ Modal: false })
        //         }, 3000);
        //         this.setState({ 
        //             name: '',
        //             email:'',
        //             contact:'',
        //             message:'',
        //         })
        //     }
           
        
        // });
      }
    render() {
        return (
                <div className="row-contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="themesflat-contact-form style-2 w100 clearfix">
                                    <form action="mailto:md@supersteelmills.com" 
                                        className="contact-form wpcf7-form">
                                        <span className="wpcf7-form-control-wrap your-name">
                                            <input type="text" tabIndex={1} id="name" name="name"  value={this.state.author} onChange={this.handleChange}
                                                className="wpcf7-form-control" placeholder="Name*" required />
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-email">
                                            <input type="email" tabIndex={3} id="email" name="email"  value={this.state.email} onChange={this.handleChange}
                                                className="wpcf7-form-control" placeholder="Your Email*" required />
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-phone">
                                            <input type="text" tabIndex={2} id="phone" name="contact"  value={this.state.phone} onChange={this.handleChange}
                                                className="wpcf7-form-control" placeholder="Contact no*" />
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-message">
                                            <textarea name="message" tabIndex={5} cols={40} rows={10} value={this.state.message} onChange={this.handleChange}
                                                className="wpcf7-form-control wpcf7-textarea" placeholder="Message*" required
                                            />
                                            </span>                                                             
                                <span className="wrap-submit">
                                
                                    <input type="submit" defaultValue="SEND US" className="submit wpcf7-form-control wpcf7-submit" id="submit" name="submit" />
                                    {this.state.Modal && <div className="div-msg">Message Send Successfully</div>}
                                </span>                                                            
                                </form>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-map style-2" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={81} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormContact02;