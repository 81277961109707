const menus = [
    {
        id: 1,
        name: 'Home',
        link: '/',
        namesub: [
            {
                id: 1,
                sub: 'Home',
                links: '/'
            },
            // {
            //     id: 2,
            //     sub: 'Home 02',
            //     links: '/home-02'
            // },
        ]
    },
    {
        id: 2,
        name: 'About Us',
        link: '/about-us',
        namesub: [
            {
                id: 1,
                sub: 'About Us',
                links: '/about-us'
            },
            // {
            //     id: 2,
            //     sub: 'About Detail',
            //     links: '/about-detail'
            // },
            // {
            //     id: 3,
            //     sub: 'About Team',
            //     links: '/about-team'
            // },
            // {
            //     id: 4,
            //     sub: 'About Carees',
            //     links: '/about-carees'
            // }
        ],
    },
    {
        id: 3,
        name: 'Grade 40',
        link: '/grade-40',
        namesub: [
            {
                id: 1,
                sub: 'Grade 40',
                links: '/'
            },
            // {
            //     id: 2,
            //     sub: 'Home 02',
            //     links: '/home-02'
            // },
        ]
    },
    {
        id: 4,
        name: 'Grade 60',
        link: '/grade-60',
        namesub: [
            {
                id: 1,
                sub: 'Grade 60',
                links: '/'
            },
            // {
            //     id: 2,
            //     sub: 'Home 02',
            //     links: '/home-02'
            // },
        ]
    },
    {
        id: 5,
        name: 'Projects',
        link: '/project',
        namesub: [
            {
                id: 1,
                sub: 'Projects',
                links: '/project'
            },
            // {
            //     id: 2,
            //     sub: 'Projects Full Width',
            //     links: '/project-full-width'
            // },
            // {
            //     id: 3,
            //     sub: 'Projects Detail',
            //     links: '/project-detail'
            // }
        ],
    },
    // {
    //     id: 5,
    //     name: 'Page',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Page Testimonial',
    //             links: '/page-testimonial'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Page Pricing',
    //             links: '/page-pricing'
    //         }
    //     ],
    // },
    {
        id: 6,
        name: 'Products',
        link: '/services',
        namesub: [
            {
                id: 1,
                sub: 'Services',
                links: '/services'
            },
            // {
            //     id: 2,
            //     sub: 'Services Detail',
            //     links: '/services-detail'
            // }
        ],
    },
    {
        id: 7,
        name: 'Clients',
        link: '/blog',
        namesub: [
            {
                id: 1,
                sub: 'Clients',
                links: '/blog'
            },
            // {
            //     id: 2,
            //     sub: 'Blog Single',
            //     links: '/blog-single'
            // }
        ],
    },
    {
        id: 8,
        name: 'Quality',
        link: '/blog-single',
        namesub: [
            {
                id: 1,
                sub: 'Quality',
                links: '/blog-single'
            },
            // {
            //     id: 2,
            //     sub: 'Blog Single',
            //     links: '/blog-single'
            // }
        ],
    },
    {
        id: 9,
        name: 'Supply',
        link: '/services-detail',
        namesub: [
            {
                id: 1,
                sub: 'Supply',
                links: '/services-detail'
            },
            // {
            //     id: 2,
            //     sub: 'Blog Single',
            //     links: '/blog-single'
            // }
        ],
    },
    {
        id: 10,
        name: 'Contact',
        link: '/contact-02',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'Contact 1',
            //     links: '/contact-01'
            // },
            {
                id: 2,
                sub: 'Contact',
                links: '/contact-02'
            }
        ],
    }
]

export default menus;