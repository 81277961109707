import React, { Component } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import {TitleHeading} from '../layouts/home01/index'
import {ServiceSlide} from '../layouts/services/index';
class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Products",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Products',
                    heading: 'Products'
                }
            ],
            projects: [
                {
                id: 1,
                classnames: 'heading',
                title: 'Our Products',
                text: 'We know that customer needs and desires are frequently changing, and so are industry regulations, so the importance of matching these demands with product availability is ever increasing. We consider this the foundation of our business and can assure our customers that our range of steel products will not disappoint.',
                classtext: 'sub-heading',
                }
            ],
        }
    }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }

                        <div className="row-iconbox">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                                            {
                                                this.state.projects.map(data => (
                                                <TitleHeading data={data} key={data.id} />
                                                    ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            <ServiceSlide />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;