import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Counter, LeaderTeam } from '../layouts/about'
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {TitleHeading , IconBox, About, Overview, Faq, Partner} from '../layouts/home01/index'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import axiosWithoutAuth from '../../config/service'

import grade40 from '../../images/grade60/grade60.jpg';

export default class Grade60 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Grade 60",
                }
            ],
            headingchoose: [
                {
                id: 1,
                classnames: 'heading',
                title: 'YOUR BEST CHOOSE',
                text: 'More than 500 projects completed in Pakistan - With over 20 years of combined experience, we have the knowledge to serve you.',
                classtext: 'sub-heading',
                }
            ],
            steel_bar: 
                {
                id: 1,
                classnames: 'heading',
                title: 'GRADE 60 steel bars',
                text: 'Grade 60 rebar offers a minimum yield strength of 60,000 pounds per square inch, or 420 megapascals on the metric grading scale. It also features a continuous line system, with one line running along the length of the bar which is offset a minimum of five spaces from the center. These characteristics make grade 60 rebar particularly well-suited for medium- to heavy-duty concrete reinforcement applications.',
                classtext: 'sub-heading',
                },
            
            astm_grade: 
                {
                id: 1,
                classnames: 'heading',
                title: 'ASTM Grade 60 Rebar',
                text: 'We also allow our clients to submit custom specifications for their rebar orders, which are then manufactured in our state-of-the-art in-house fabrication facility and always meet demanding industry standards for quality and performance. Because we’re the largest supplier of rebar and steel mesh products in the United States, we have an extensive network of distribution centers which enables us to meet your timelines with ease. We also leverage our ability to source materials at advantageous rates to provide our clients with highly competitive prices in addition to delivering value-added service time after time.',
                classtext: 'sub-heading',
                },
            
            product: 
                {
                id: 1,
                classnames: 'heading',
                title: 'Product',
                text: 'SUPER STEEL is the exclusive producers of grade 60 and 75 deformed steel bars according to ASTM 615 and ASTM 706, There is no doubt to the fact that we are the only manufacturers in Pakistan that solely deal with grade 60 steel. Not just any grade 60 steel but the best grade 60 steel. ',
                classtext: 'sub-heading',
                },
            
            size: 
                {
                id: 1,
                classnames: 'heading',
                title: 'Size and bending machine pin diameter',
                text: 'Bend dia when steel bar is bent at a certain angel, a standard pin diameter is required for each size. Standards for 3,4 and 5 sooter, the pin diameter will be 3.5d, for 6,7 and 8 sooter it will be 5d and for 10,11 sooter it will be 7d. ',
                classtext: 'sub-heading',
                },
            
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Grade 60',
                    heading: 'Grade 60'
                }
            ]
        }
    }

    componentDidMount(){
        // axiosWithoutAuth().get(`/api/v1/content/grade60`)
        //   .then(response =>{
        //    const data = response.data.data
        //    console.log("data",data)
           
        // this.setState({ 
        //     steel_bar : Object.assign({}, this.state.steel_bar , {
        //         title: data[0].heading,
        //         text: data[0].description
        //     }),

        //     astm_grade : Object.assign({}, this.state.astm_grade , {
        //         title: data[1].heading,
        //         text: data[1].description
        //     }),

        //     product : Object.assign({}, this.state.product , {
        //         title: data[2].heading,
        //         text: data[2].description
        //     }),

        //     size : Object.assign({}, this.state.size , {
        //         title: data[3].heading,
        //         text: data[3].description
        //     })
        
        // })
        // });
    }

    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content"> 

                                        <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.steel_bar} key={this.state.steel_bar.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className="container grade60">
                                                <img src={grade40} alt="grade60" className="grade60-img"/>
                                                
                                            </div>    
                                                
                                            

                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.astm_grade} key={this.state.astm_grade.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.product} key={this.state.product.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.size} key={this.state.size.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="container grade60-product">
                                                <h2>Product</h2>
                                                <p>SUPER STEEL is the exclusive producers of grade 60 and 75 deformed steel bars according to ASTM 615 and ASTM 706</p>
                                                <p>There is no doubt to the fact that we are the only manufacturers in Pakistan that solely deal with grade 60 steel. Not just any grade 60 steel but the best grade 60 steel.</p>
                                            </div>

                                            <div className="container grade60-product">
                                                <h2>Size and bending machine pin diameter</h2>
                                                <p>Bend dia when steel bar is bent at a certain angel, a standard pin diameter is required for each size.</p>
                                                <p>Standards for 3,4 and 5 sooter, the pin diameter will be 3.5d, for 6,7 and 8 sooter it will be 5d and for 10,11 sooter it will be 7d.</p>
                                            </div> */}

                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={30} data-smobile={20} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}
