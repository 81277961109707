import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class SingleContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            author: '',
            email:'',
            contact:'',
            message:''
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        this.setState({[event.target.name] : event.target.value});
      }
    
      handleSubmit(event) {
        // alert('A message was submitted: ' + this.state.message);
        event.preventDefault();
      }
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <article className="hentry data-effect">
                        <div className="post-media data-effect-item has-effect-icon offset-v-25 offset-h-24 clerafix">
                            <Link to="/blog-single"><img src="assets/img/quality/testing.png" alt="altimage" /></Link>
                            
                            <div className="overlay-effect bg-color-1" />
                            <div className="elm-link">
                                <Link to="/blog-single" className="icon-1" />
                            </div>
                        </div>
                        <div className="post-content-wrap ">
                            <h2 className="post-title">
                                <span className="post-title-inner">
                                <h2 className="comments-title">TESTING FACILITIES</h2>
                                </span>
                            </h2>
                            
                            <div className="post-content post-excerpt margin-bottom-43">
                                <p className="line-height-27 letter-spacing-005">By the grace of Almighty Allah, Super Steel is one of the very few steel manufacturing units of Pakistan which has its own steel testing laboratories with most modern universal machine with 100 ton load bearing capacity. Quality reports are provided with all consignments.</p>
                                <div className="box-excerpt clearfix">
                                    <div className="thumb">
                                        <img src="assets/img/quality/s5.png" alt="altimage" />
                                    </div>
                                    <div className="text">
                                        <p className="line-height-27 letter-spacing-005">Each phase of production is closely monitored by highly skilled personnel and each lot is tested to make sure it conforms to standard specifications.</p>
                                    </div>
                                </div>
                                <h2 className="comments-title">QUALITY CONCEPT</h2>
                                <p className="line-height-27 no-margin letter-spacing-01">Quality and reliability are used synonymously which can be defined as the acceptability of a product or service will depend on its ability to function satisfactorily over a period of time. By consistently meeting the customers requirements we can move to a different plane of satisfaction which is delighting the customer..!.</p>

                                <h2 className="comments-title">QUALITY SYSTEM</h2>
                                <p className="line-height-27 no-margin letter-spacing-01">As a minimum, all processes within the Quality System of Nomee Industries have been documented, which comply with ISO 9001 : 2000. The primary and supporting responsibilities for each functional group and department are described in the Management out: Responsibility Practice. The responsibility for ensuring compliance lies with the quality representative of the department..! </p>

                                <h2 className="comments-title">QUALITY ASSURANCE</h2>
                                <p className="line-height-27 no-margin letter-spacing-01">A strict quality control and stage inspection coupled with repetitive tests ensures quality of the processes resulting to quality of product. This guarantees the conformance of product to internationally accepted standards / specifications. Quality of the end product is checked and monitored by repeated sampling in working hours. </p>
                            </div>
                            
                        </div>
                    </article>
                   
                    <div id="comments" className="comments-area">
                        
                        <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title margin-bottom-31">Get Your Quote</h3>
                            <form action="#" method="post" id="commentform" className="comment-form" onSubmit={this.handleSubmit}>
                                <div className="text-wrap clearfix">
                                    <fieldset className="name-wrap">
                                        <input type="text" id="author" className="tb-my-input" name="author" tabIndex={1} value={this.state.author} onChange={this.handleChange}
                                            placeholder="Name*" size={32} aria-required="true"/>
                                    </fieldset>
                                    <fieldset className="email-wrap">
                                        <input type="email" id="email" className="tb-my-input" name="email" tabIndex={2} value={this.state.email} onChange={this.handleChange}
                                            placeholder="Email*" size={32} aria-required="true" />
                                    </fieldset>
                                </div>
                                <fieldset className="message-wrap">
                                        <input type="text" id="contact" className="tb-my-input" name="contact" tabIndex={2} value={this.state.contact} onChange={this.handleChange}
                                            placeholder="Contact no*" size={32} aria-required="true" />
                                    </fieldset>
                                <fieldset className="message-wrap">
                                    <textarea id="comment-message" name="message" rows={8} tabIndex={4} placeholder="Message*" value={this.state.message} onChange={this.handleChange}
                                        aria-required="true"  />
                                </fieldset>
                                <p className="form-submit">
                                <input name="submit" type="submit" id="comment-reply" className="submit" defaultValue="SEND US" />
                                <input type="hidden" name="comment_post_ID" defaultValue={100} id="comment_post_ID" />
                                <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SingleContent;