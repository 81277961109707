import React, { Component } from 'react';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordion: [
                {
                    id: 1,
                    classname: 'accordion-item active',
                    heading: 'What is Grade 40 or Grade 60 Steel bar?',
                    text: '40 grade steel has a yield of 280 MPa or 40 Ksi. 60 is a higher grade and is the mostly used grade in reinforced concrete. Grade 40 means steel having yield strength of 40Ksi and grade 60 means steel having yield strength of 60 Ksi.'
                },
                {
                    id: 2,
                    classname: 'accordion-item',
                    heading: 'Am I buying directly from the factory?',
                    text: 'Yes! Super Steel is a steel building manufacturer. We own our own factory in Plot # 34, Street No 10, Near Al Meezan Kanta I-9/2, ISLAMABAD. We also staff in-house draftsmen and detailers that work solely on Super Steel Projects. Super Steel are unique as we are in control of the stringent specifications, measurements, standards, and guidelines for your building.'
                },
                {
                    id: 3,
                    classname: 'accordion-item',
                    heading: 'Can I visit you?',
                    text: 'Absolutely! We did love to have you. If possible, call ahead so we can make arrangements. However, walk-ins are welcome too.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="col-md-6">
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                <div className="themesflat-content-box" data-margin="0 0 0 18px" data-mobilemargin="0 0 0 0">
                    <div className="themesflat-headings style-1 clearfix">
                        <h2 className="heading">FAQs</h2>
                        <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={38} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-accordions style-1 has-icon icon-left iconstyle-1 clearfix">
                        {
                            this.state.accordion.map(data => (
                                <div className={data.classname} key={data.id}>
                                    <h3 className="accordion-heading"><span className="inner">{data.heading}</span></h3>
                                    <div className="accordion-content">
                                        <div>{data.text}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default Faq;