import React, { Component } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import {TitleHeading} from '../layouts/home01/index'

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Clients",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Clients',
                    heading: 'Clients'
                },
            ],
            projects: [
                {
                id: 1,
                classnames: 'heading',
                title: 'Our Clients',
                text: 'Day in day out, we make sure our customers are served by the safest, highest quality, most productive and profitable steel and steel products. Super Steel does so by practicing five stanchions of commercial excellence. We have an expert steelwork team, with a detailed knowledge of the trades required for architecture and construction.',
                text2: 'Our value propositions are catered to the key market segments that our customers serve. That’s why we’ve set our customer satisfaction standards significantly higher than our competitors. We want to guarantee our customers’ expectations are not only met, but exceeded',
                classtext: 'sub-heading',
                }
            ],
        }
    }
    render() {
        return (
            <div className="header-fixed sidebar-right header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }

                        <div className="row-iconbox">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                                            {
                                                this.state.projects.map(data => (
                                                <TitleHeading data={data} key={data.id} />
                                                    ))
                                            }
                                            <div className="themesflat-spacer clearfix" data-desktop={42}
                                                                data-mobile={35} data-smobile={35} />
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <div class="container">
                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/1.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/2.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/3.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/4.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/5.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/6.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/7.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/8.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/9.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/10.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/11.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/12.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/13.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/14.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/15.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/16.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/17.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/18.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/19.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/20.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/21.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/22.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/23.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/24.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/25.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/26.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/27.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/28.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/29.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/30.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/31.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/32.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/33.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/34.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/35.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/36.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/37.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/38.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/39.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/40.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/41.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/42.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/43.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/44.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/45.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/46.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/47.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/48.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/49.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/50.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/51.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/52.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/53.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/54.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/55.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/56.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/57.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/58.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/59.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/60.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row-client">
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/61.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/62.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/63.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="client-card-inner">
                                        <div class="post-image">
                                            <img src="assets/img/clients/64.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>




                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Blog;