import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Counter, LeaderTeam } from '../layouts/about'
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {TitleHeading , IconBox, About, Overview, Faq, Partner} from '../layouts/home01/index'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import axiosWithoutAuth from '../../config/service'

import grade40 from '../../images/grade40/grade40.jpeg';

export default class Grade40 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Grade 40",
                }
            ],
            headingchoose: [
                {
                id: 1,
                classnames: 'heading',
                title: 'YOUR BEST CHOOSE',
                text: 'More than 500 projects completed in Pakistan - With over 20 years of combined experience, we have the knowledge to serve you.',
                classtext: 'sub-heading',
                }
            ],
            steel_bar: 
                {
                id: 1,
                classnames: 'heading',
                title: 'GRADE 40 steel bars',
                text: 'In construction engineering, steel reinforcing bar or “rebar” is widely used in tandem with concrete components, since steel and concrete have similar heat expansion coefficients. Thus, rebar can be used to increase the inherent tactile strength of concrete, adding long-term stability to the structure. To provide consumers with a reliable means of gauging the suitability of specific products for specific applications, all rebar products are standardized and graded. Rebar with a grade 40 designation offers a minimum yield strength of 40,000 pounds per square inch and conforms to ASTM A-615 performance standards. Its metric counterpart is grade 280 rebar, with an equivalent minimum yield strength of 280 megapascals. This makes grade 40 rebar an excellent choice for light- to medium-duty concrete reinforcing applications.',
                classtext: 'sub-heading',
                },
                
            
            astm_grade: 
                {
                id: 1,
                classnames: 'heading',
                title: 'ASTM Grade 40 Rebar',
                text: 'We offer our customers a comprehensive range of rebar products, including grade 40 black rebar, grade 60 black rebar, and a complete selection of standard imperial rebar sizes. In addition, we are pleased to offer customers the ability to customize products according to their own specifications. Our custom rebar fabrication facilities are outfitted with state-of-the-art equipment to ensure precision, compliance with demanding industry standards, and customer satisfaction.',
                classtext: 'sub-heading',
                },
            
            products: 
                {
                id: 1,
                p1:'ASTM 706',
                p2:'ASTM 615',
                p3:'BS 4449:2005',
                classnames: 'heading',
                title: 'Products',
                text: 'It can be quite a challenge to find the right steel manufacturer; one you like working with and whose factory produces the best quality product at a fair price. SUPER STEEL is the exclusive producers of grade 40 and 75 deformed steel bars according to ASTM 615 and ASTM 706. There is no doubt to the fact that we are the only manufacturers in Pakistan that solely deal with grade 40 steel. Not just any grade 40 steel but the best grade 40 steel.',
                classtext: 'sub-heading',
                },
            
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Grade 40',
                    heading: 'Grade 40'
                }
            ]
        }
    }

    componentDidMount(){
        // axiosWithoutAuth().get(`/api/v1/content/grade40`)
        //   .then(response =>{
        //    const data = response.data.data
        // //    console.log("data",data)
           
        // this.setState({ 
        //     steel_bar : Object.assign({}, this.state.steel_bar , {
        //         title: data[0].heading,
        //         text: data[0].description
        //     }),

        //     astm_grade : Object.assign({}, this.state.astm_grade , {
        //         title: data[1].heading,
        //         text: data[1].description
        //     }),

        //     products : Object.assign({}, this.state.products , {
        //         title: 'Products',
        //         text: data[2].description
        //     })
        
        // })
        // });
    }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content"> 

                                        <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.steel_bar} key={this.state.steel_bar.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className="container grade40">
                                                <img src={grade40} alt="grade40" className="grade40-img"/>
                                                
                                            </div>    
                                                
                                            

                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.astm_grade} key={this.state.astm_grade.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={60} data-smobile={60} />
                                                            <TitleHeading data={this.state.products} key={this.state.products.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="container grade40-product">

                                                
                                                <ol>
                                                    <li><b>ASTM 706</b></li>
                                                    <li><b>ASTM 615</b></li>
                                                    <li><b>BS 4449:2005</b></li>
                                                </ol>
                                                <p>It can be quite a challenge to find the right steel manufacturer; one you like working with and whose factory produces the best quality product at a fair price.
                                                SUPER STEEL is the exclusive producers of grade 40 and 75 deformed steel bars according to ASTM 615 and ASTM 706
                                                There is no doubt to the fact that we are the only manufacturers in Pakistan that solely deal with grade 40 steel. Not just any grade 40 steel but the best grade 40 steel.</p>
                                            </div> */}

                                            <div className="themesflat-spacer clearfix" data-desktop={60}
                                                                data-mobile={30} data-smobile={20} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}
