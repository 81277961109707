import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopBar extends Component {
    render() {
        return (
            <div id="top-bar">
                <div id="top-bar-inner" className="container">
                    <div className="top-bar-inner-wrap">
                        <div className="top-bar-content">
                            <div className="inner">
                                <span className="address content">Plot # 34, Street No 10, Near Al Meezan Kanta I-9/2, Islamabad Pakistan.</span>
                                <span className="phone content">+92-346-6551788</span>
                                {/* <span className="time content">Mon-Sat: 8am - 6pm</span> */}
                            </div>                            
                        </div>
                        <div className="top-bar-socials">
                            <div className="inner">
                                <span className="text">Follow us:</span>
                                <span className="icons">
                                    <a href="https://www.facebook.com/supersteelmill/"><i className="fa fa-facebook" /></a>
                                    <a href="https://www.instagram.com/supersteelmills/"><i className="fa fa-instagram" /></a>

                                    {/* <Link to="https://www.facebook.com/supersteelmill/" onClick={() => {window.location.href="https://www.facebook.com/supersteelmill/"}}><i className="fa fa-facebook" /></Link>
                                    
                                    <Link to="https://www.instagram.com/supersteelmills/" onClick={() => {window.location.href="https://www.instagram.com/supersteelmills/"}}><i className="fa fa-instagram" /></Link> */}
                                </span>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

export default TopBar;